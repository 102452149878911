import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import BackgroundImage from '../../../assets/img/bg-login.png';
import PasswordField from '../../../components/common/PasswordField/PasswordField';
import * as ACTION from '../../../store/actions/authActions';
import * as REQUEST from '../../../store/actions/requestActions';
import { clearErrors } from '../../../store/actions/errorActions';
import Veridata from '../../../assets/img/icons/veridata.png';
import '../../styles/forgotpassword.css';
import packageJson from '../../../../package.json';
import * as CONSTANTS from '../../../utils/Constants';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  forgotPassword: {
    marginBottom: theme.spacing(0.5),
    maxWidth: '368px',
    '& span': {
      fontSize: '14px',
      lineHeight: '24px',
    },
  },
  passwordText: {
    color: '#55565c',
  },
  forgotPassText: {
    fontWeight: 'bold',
    color: '#41B67F',
    cursor: 'pointer',
  },
  forgotPassModalContent: {
    padding: '24px 24px 44px',
    '&:first-child': {
      paddingTop: '24px',
    },
  },
  forgotPassModalClose: {
    border: '1px solid rgba(47, 53, 66, 0.4)',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& svg': {
      fill: '#2f3542',
    },
  },
  forgotPassModalTitle: {
    fontSize: '36px',
    lineHeight: '48px',
    fontWeight: 'bold',
  },
  forgotPassModalText: {
    margin: '26px 0 42px',
    fontSize: '15px',
    lineHeight: '22px',
  },
  forgotPassModalBtn: {
    textAlign: 'right',
  },
  powered: {
    marginTop: '100px',
    fontSize: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  veridata: {
    marginLeft: '5px',
    width: '66px',
    height: '16px',
  },
  image: {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    paddingLeft: '0',
    '& .MuiInputBase-root': {
      maxWidth: '368px',
    },
    '& .MuiInputLabel-root': {
      '& span': {
        display: 'none',
      },
    },
  },
  paper: {
    marginTop: 70,
    marginRight: 100,
    marginLeft: 100,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
  },
  alertStyle: {
    width: '100%',
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    height: '40px',
    width: '165px',
    margin: theme.spacing(1),
    marginTop: '20px',
  },
}));

const ForgotPassword = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const error = useSelector((state) => state.error);
  const auth = useSelector((state) => state.auth);

  const history = useNavigate();
  const { token } = useParams();

  const [password, setPassword] = useState('');
  const [isMismatchPassword, setIsMismatchPassword] = useState(false);

  useEffect(() => {
    if (error.id === 'PASSWORD_RESET_FAIL') {
      toast.error(error.msg.message)
    }
  }, [error]);

  useEffect(() => {
    const userRoles = JSON.parse(localStorage.getItem('roles'));

    const userRoleRequestor =
      userRoles !== null ? userRoles.some((roles) => CONSTANTS.REQUESTOR.indexOf(roles) >= 0) : userRoles;
    const userRoleAdminAssistant =
      userRoles !== null ? userRoles.some((roles) => CONSTANTS.ADMIN_ASSISTANT.indexOf(roles) >= 0) : userRoles;
    const userRoleSystemAdmin =
      userRoles !== null ? userRoles.some((roles) => CONSTANTS.SYSTEM_ADMIN.indexOf(roles) >= 0) : userRoles;

    if (auth.isAuthenticated) {
      if (userRoleRequestor) {
        history.push('/app/requests');
      }

      if (userRoleAdminAssistant) {
        history.push('/app/adminassistant');
      }

      if (userRoleAdminAssistant) {
        history.push('/app/statistics');
      }
    }
  }, [auth.isAuthenticated]);

  useEffect(() => {
    if (auth.forgotPassword.newPassword && auth.forgotPassword.confirmPassword) {
      if (auth.forgotPassword.newPassword !== auth.forgotPassword.confirmPassword) {
        setIsMismatchPassword(true);
      } else {
        setIsMismatchPassword(false);
      }
    } else {
      setIsMismatchPassword(false);
    }
  }, [auth.forgotPassword.newPassword, auth.forgotPassword.confirmPassword]);
  
  const passwordHandler = (password) => {
    setPassword(password);
    dispatch(ACTION.setFgtPwdNewPassword(password))
  }

  const confirmpasswordHandler = (confirmpassword) => {
    dispatch(ACTION.setFgtPwdConfirmPassword(confirmpassword))
  }

  const onSubmit = (e) => {
    e.preventDefault();

    const re = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[ !"#$%&'()*+,-./:;<=>?@[\]\\^_`{|}~])(?=.{8,})/);
		const isOk = re.test(password);
    if (isOk) {      
      let encryptPassword = ACTION.encryptCredentials(password);
      const resetpassUser = {
        password: encryptPassword,
        token,
      };

      dispatch(ACTION.resetpassword(resetpassUser));
      dispatch(REQUEST.clearAllFilter());
      dispatch(clearErrors());
    } else {
      if (!password.match(/^(?=.{8,})/)) {
        toast.error('New password must be at least 8 characters.');
      }
      if (!password.match(/^(?=.*[A-Z])/)) {
        toast.error('New password must contain at least one (1) uppercase.');
      }
      if (!password.match(/^(?=.*[a-z])/)) {
        toast.error('New password must contain at least one (1) lowercase.');
      }
      if (!password.match(/^(?=.*[0-9])/)) {
        toast.error('New password must contain at least one (1) numeric.');
      }
      if (!password.match(/^(?=.*[ !"#$%&'()*+,-./:;<=>?@[\]\\^_`{|}~])/)) {
        toast.error('New password must contain at least one (1) special character.');
      }
    }
  };

  const loginPageButton = () => {
    // 217
    history("/login");
  };

  return (
    <Grid container justify="center" alignItems="center" component="main" className={`login-wrapper ${classes.image}`}>
      <CssBaseline />
      <Grid item style={{ paddingLeft: '8%' }}>
        <div className="login-content">
          <form className={classes.form} onSubmit={(e) => onSubmit(e)}>
            <Grid container className={classes.forgotPassword}>
              <Grid item xs={6}>
                <span className={classes.passwordText}>New Password</span>
              </Grid>
            </Grid>
            <div className="icon-show-cust">
              <PasswordField
                required
                name="password"
                variant="outlined"
                fullWidth
                id="password"
                autoComplete="current-password"
                onChange={(e) => passwordHandler(e.target.value)}
                inputProps={{ 'data-cy': 'password_login' }}
              />
            </div>
            <Grid container className={classes.forgotPassword}>
              <Grid item xs={6}>
                <span className={classes.passwordText}>Confirm New Password</span>
              </Grid>
            </Grid>
            <div className="icon-show-cust">
              <PasswordField
                error={isMismatchPassword}
                helperText={isMismatchPassword ? 'New Password and Confirm New Password must match' : ''}
                required
                name="confirmpassword"
                variant="outlined"
                fullWidth
                id="confirmpassword"
                autoComplete="current-password"
                onChange={(e) => confirmpasswordHandler(e.target.value)}
                inputProps={{ 'data-cy': 'password_login' }}
              />
            </div>
            <Typography style={{ fontSize: '13px', fontWeight: 'italic' }}>
              <i>Note: Password must be at least 8 characters in length, and must contain uppercase, lowercase, numeric and special characters.</i>
            </Typography>
            <Button
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={() => loginPageButton()}
              variant="outlined"
              data-cy="submit_login"
            >
              Cancel
            </Button>
            <Button
              disabled={!auth.forgotPassword.newPassword || !auth.forgotPassword.confirmPassword || isMismatchPassword}
              type="submit"
              variant="contained"
              color="primary"
              className={classes.submit}
              data-cy="submit_login"
            >
              Save
            </Button>
          </form>
          <div className={classes.powered}>
            <p>
              Powered by: <img src={Veridata} className={classes.veridata} alt="veridata" />
            </p>
            <div>v{packageJson.version}</div>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default ForgotPassword;
